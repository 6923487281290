@import "core";
@import 'normalize';
@import 'icomoon';
@import 'animations';

@import 'overrides';

@import 'partials/wrapper';
@import 'partials/hyperlink';
@import 'partials/wysiwyg';
@import 'partials/grid';

@font-face {
	font-family: 'icomoon';
	src: url('/assets/fonts/icomoon.eot?1');
	src: url('/assets/fonts/icomoon.eot?1#iefix') format('embedded-opentype'),
	url('/assets/fonts/icomoon.woff?1') format('woff'),
	url('/assets/fonts/icomoon.ttf?1') format('truetype'),
		url('/assets/fonts/icomoon.svg?1#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@include fontFace('HelveticaNeue', '/assets/fonts/subset-HelveticaNeueLTStdLtCn', $weight-light);
@include fontFace('HelveticaNeue', '/assets/fonts/subset-HelveticaNeueLTStdCn', $weight-regular);
@include fontFace('HelveticaNeue', '/assets/fonts/subset-HelveticaNeueLTStdMedCn', $weight-medium);
@include fontFace('HelveticaNeue', '/assets/fonts/subset-HelveticaNeueLTStdCn-Bold', $weight-bold);
@include fontFace('HelveticaNeue', '/assets/fonts/subset-HelveticaNeueLTStdBlkCn', $weight-black);
@include fontFace('OCR A', '/assets/fonts/subset-OCRAStd', $weight-regular);

body{
	@include font-main;
	@include font-regular;
	color: $color-text;
	font-size: 1.4rem;
	background-color: $color-white;
	letter-spacing: -.015em;
	opacity: 1;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

html {
	&[class^="block-overflow-"],
	&[class*=" block-overflow-"] {
		overflow: hidden;
		body {
			overflow-y: hidden;
		}
	}
}

html {
	font-size: 10px;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;

	@include media(1180) {
		@include calcFontSize(1180);
	}
	// @include media(1439) {
	// 	@include calcFontSize(1440);
	// }

	@include media {
		@include calcFontSize(375);
	}
}

#root {
	margin: 0 auto;
}