$icomoonIcons: (
	"secure": "\e93d",
	"calendar": "\e93c",
	"notification": "\e93b",
	"trash": "\e93a",
	"map": "\e939",
	"home": "\e936",
	"menu": "\e937",
	"creditcard": "\e935",
	"coin": "\e930",
	"return": "\e931",
	"help": "\e932",
	"wallet": "\e933",
	"wallet-add": "\e934",
	"menu-nav": "\e92f",
	"qr": "\e92d",
	"back": "\e92e",
	"user": "\e92a",
	"logout": "\e92b",
	"address": "\e927",
	"phone": "\e928",
	"door": "\e918",
	"comment-check": "\e919",
	"envelope-dark": "\e929",
	"envelope": "\e91f",
	"pdf": "\e922",
	"ticket": "\e925",
	"ticket-qr": "\e925",
	"time": "\e926",
	"party": "\e900",
	"mic": "\e904",
	"social": "\e907",
	"confusion": "\e908",
	"stage": "\e909",
	"stage-2": "\e938",
	"concert": "\e912",
	"switch": "\e916",
	"map-pin": "\e917",
	"heart-empty": "\e91d",
	"heart-full": "\e91e",
	
	// Symbols
	"search": "\e91c",
	"spinner": "\e915",
	"warning": "\e91a",
	"info": "\e91b",
	"arrow-left": "\e913",
	"arrow-right": "\e914",
	"arrow-left-short": "\e920",
	"arrow-right-short": "\e921",
	"angle-left": "\e90a",
	"angle-right": "\e90b",
	"angle-up": "\e90c",
	"angle-down": "\e90d",
	"check": "\e90e",
	"close": "\e90f",
	"minus": "\e910",
	"plus": "\e911",
	
	// Social
	"instagram": "\e901",
	"facebook": "\e902",
	"twitter": "\e903",
	"whatsapp": "\e905",
	"youtube": "\e906",
);