@keyframes spin {
	from {transform: translate(-50%, -50%) rotate(0deg);}
	to {transform: translate(-50%, -50%) rotate(360deg);}
}

@keyframes reverseSpin {
	from {transform: translate(-50%, -50%) rotate(0deg);}
	to {transform: translate(-50%, -50%) rotate(-360deg);}
}

@keyframes fadeLoop {
	from { opacity: 1; }
	50% { opacity: 0; }
	to { opacity: 1; }
}