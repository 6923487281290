.wrapper {
	max-width: 1180px;
	margin: 0 auto;
	width: calc(100% - 3.6rem);

	&.inner {
		width: 100%;
	}

	&.narrow {
		max-width: 980px;
	}

	&.narrower {
		max-width: 780px;
	}
}