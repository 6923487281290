.wysiwyg {
	@include font-regular;
	line-height: 1.33333em;

	> * {
		margin: .8em 0 .3em;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&.placeholder {
			width: 80%;
			height: 15rem;

			&:nth-child(3n+2) {
				width: 60%;
				height: 10rem;
			}

			&:nth-child(3n+3) {
				width: 70%;
				height: 12rem;
			}
		}
	}

	h1, h2, h3, h4, h5, h6 {
		@include font-black;
		margin-top: 1.5em;
		margin-bottom: .8em;
		font-size: 1.1em;

		+ * {
			margin-top: 0em;
		}
	}

	h1 {
		font-size: 1.7em;
	}

	h2 {
		font-size: 1.4em;
	}

	h3 {
		font-size: 1.1em;
	}

	strong, b {
		color: $color-text;
	}

	.smalltext {
		font-size: .8em;
	}

	ul:not(.no-list) {
		list-style: disc inside;
	}

	ul, ol {
		&:not(.no-list) {
			li {
				list-style-position: inside;

				> p {
					display: inline-block;
					max-width: calc(100% - 3rem);
				}
				+ li {
					margin-top: 1em;
				}
			}
		}
	}

	blockquote {
		font-family: inherit;
		color: $color-text;
		font-size: 1.45em;
		line-height: 1.44em;
		position: relative;

		@include media {
			padding-left: 3.9rem;
			padding-right: 3.9rem;
		}

		&:before, &:after {
			position: absolute;
			color: $color-text;
			font-size: 6em;
			opacity: .05;

			@include media {
				font-size: 9rem;
			}
		}

		&:before {
			content: '“';
			top: .25em;
			right: calc(100% + .1em);

			@include media {
				left: 0;
				right: auto;
			}
		}

		&:after {
			content: '“';
			bottom: .25em;
			left: calc(100% + 0em);
			transform: rotate(180deg);

			@include media {
				right: 0;
				left: auto;
			}
		}
	}

	.text-small {
		font-size: .8em;

		@include media {
			font-size: .5em;
		}
	}

	table {
		width: 100%;
		border: .1rem solid $color-gray-light;
		border-collapse: collapse;
		font-size: .8em;

		td, th {
			border: .1rem solid $color-gray-light;
			padding: .5rem .5rem
		}

		th {
			@include font-semibold;
		}
	}

	&.highlight-links {
		a, button {
			& ,* {
				color: $color-primary;
				text-decoration: underline;
				
				@include media {
					&:active {
						color: darken($color-primary, $darken-ratio)
					}
				}

				@include mediaMin {
					&:hover, &:active {
						color: darken($color-primary, $darken-ratio)
					}	
				}
			}
		}
	}
}