@import "core";
.loader-container {
	position: relative;
	min-height: 10rem;

	> .loaderelem {
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.loaderelem {
	position: relative;
	height: 15rem;
	background-color: rgba($color-white, .9);
	transition: opacity $transition-anim ease;
	opacity: 0;
	display: flex!important;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 2.5rem;

	&.small {
		.loaderelem-icon {
			font-size: 1.8rem;
			width: 1.8rem;
			height: 1.8rem;
		}
	}

	&.solid {
		background-color: $color-white;

		&.dark {
			background-color: $color-bg;
		}
	}

	&.dark {
		background-color: rgba($color-bg, .9);
	}

	&.show {
		opacity: 1;
	}
	
	.loaderelem-icon {
		position: absolute;
		top: 0;
		left: 0;
		width: 2.4rem;
		height: 2.4rem;
		position: relative;
		font-size: 2.4rem;
		color: $color-primary;
		
		&:before {
			@include spin;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
		}
	}

	.loaderelem-message {
		margin-top: .7rem;
		text-align: center;
		font-size: 1.2rem;
		color: $color-text-light;
	}
}